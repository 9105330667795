import type { ComponentPublicInstance, PropType, VNode } from 'vue';
import type {
  AdditionalProduct,
  Diet,
  Recipe,
  RecipeLabel,
} from '@ruokaboksi/api-client';
import { storeToRefs } from 'pinia';
import type { ProductModalState } from '@/stores';
import IconPlus from '@/assets/icons/icon-plus.svg?component';
import IconTrash from '@/assets/icons/icon-trash.svg?component';
import Button from '@/components/Button';
import ProductPrice from '@/components/ProductPrice';
import placeholderImageUrl from '@/assets/images/placeholder-card-image.png';
import SanityPicture from '@/components/SanityPicture';
import './ProductCard.css';

export default defineComponent({
  name: 'ProductCard',
  props: {
    discount: {
      default: 0,
      type: Number,
    },
    hideActions: {
      default: false,
      type: Boolean,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    index: {
      default: 0,
      type: Number,
    },
    modalId: {
      required: true,
      type: String,
    },
    paused: {
      default: false,
      type: Boolean,
    },
    product: {
      type: Object as PropType<AdditionalProduct | Recipe>,
      required: true,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    selectCallback: {
      default: undefined,
      type: Function as PropType<(productId: string) => void>,
    },
    unselectCallback: {
      default: undefined,
      type: Function as PropType<(productId: string) => void>,
    },
    lazyLoadImages: {
      default: true,
      type: Boolean,
    },
  },
  setup(props) {
    const addButton = ref<ComponentPublicInstance<HTMLButtonElement> | null>(
      null
    );
    const removeButton = ref<ComponentPublicInstance<HTMLButtonElement> | null>(
      null
    );

    /** Dietary labels containing images of allergens and diets. */
    const dietaryLabels = computed<Diet[]>(() =>
      [
        ...((props.product &&
          'allergens' in props.product &&
          props.product.allergens) ||
          []),
        ...((props.product &&
          'diets' in props.product &&
          props.product.diets) ||
          []),
      ].filter((l) => l?.image)
    );

    /** Preparation time as a string containing short suffix. */
    const preparationTimeWithSuffix = computed<string>(() =>
      props.product &&
      'preparationTime' in props.product &&
      props.product.preparationTime
        ? `${props.product.preparationTime} min`
        : ''
    );

    /** Options for modal window. */
    const productModalOptions = computed<ProductModalState>(() => ({
      id: props.modalId || `product-modal-${props.product.id}`,
      product: props.product,
    }));
    const productModal = useProductModalStore();
    const { isModalVisible } = storeToRefs(productModal);

    /** Recipe labels containing text with styling. */
    const recipeLabels = computed<RecipeLabel[]>(
      () =>
        (props.product &&
          'recipeLabels' in props.product &&
          props.product.recipeLabels) ||
        []
    );

    const handleSelect = async (event: Event): Promise<void> => {
      // Prevent opening modal window
      event.stopImmediatePropagation();
      if (typeof props.selectCallback === 'function') {
        props.selectCallback(props.product.id);
        await nextTick(() => removeButton.value?.$el.focus());
      }
    };

    const handleUnselect = async (event: Event): Promise<void> => {
      // Prevent opening modal window
      event.stopImmediatePropagation();
      if (typeof props.unselectCallback === 'function') {
        props.unselectCallback(props.product.id);
        await nextTick(() => addButton.value?.$el.focus());
      }
    };

    const openModalOnEnter = (event: KeyboardEvent): void => {
      if (isModalVisible.value || event.code !== 'Enter') return;
      event.preventDefault();
      productModal.open(productModalOptions.value);
    };

    const openModalOnClick = (event: Event): void => {
      const target = event?.target as HTMLElement;
      // Skip these elements within the container
      if (
        target &&
        typeof target.className === 'string' &&
        typeof target.className.includes === 'function' &&
        (target.className.includes('button') ||
          target.className.includes('icon') ||
          target.className.includes('label-text'))
      )
        return;
      event.preventDefault();
      productModal.open(productModalOptions.value);
    };

    const isOutOfStock = computed<boolean>(() => {
      if ('isOutOfStock' in props.product && props.product?.isOutOfStock) {
        return true;
      }

      return false;
    });

    return {
      addButton,
      dietaryLabels,
      handleSelect,
      handleUnselect,
      isModalVisible,
      openModalOnClick,
      openModalOnEnter,
      preparationTimeWithSuffix,
      recipeLabels,
      removeButton,
      isOutOfStock,
    };
  },
  render(): VNode | null {
    if (!this.product) return null;
    return (
      <div
        class="product-card"
        data-testid={`product-card-${this.product.id}`}
        title={this.product.title}
      >
        <div
          aria-controls={this.modalId}
          aria-expanded={this.isModalVisible}
          class="product-card-container"
          onClick={this.openModalOnClick}
          onKeydown={this.openModalOnEnter}
          role="button"
          tabindex={0}
        >
          <div class="product-card-header">
            <h3 class="product-card-title">
              {formatTitle(this.product.title)}
            </h3>
            <ProductPrice product={this.product} discount={this.discount} />
          </div>
          <div class="product-card-content">
            <SanityPicture
              alt={this.product.plateImage?.alt ?? this.product.title}
              class="product-card-image"
              src={
                this.product.plateImage?.sizes?.medium ??
                this.product.mainImage?.sizes?.medium ??
                placeholderImageUrl
              }
              lazy={this.lazyLoadImages}
            />
            {this.dietaryLabels.length ? (
              <div class="dietary-labels">
                {this.dietaryLabels.map(
                  (label, index) =>
                    label &&
                    label.image &&
                    label.image.sizes && (
                      <SanityPicture
                        alt={label.image.alt || label.title}
                        class="icon"
                        key={index}
                        src={label.image.sizes.thumbnail}
                        lazy={this.lazyLoadImages}
                      />
                    )
                )}
              </div>
            ) : null}
            <div class="product-card-labels">
              {this.preparationTimeWithSuffix && (
                <dl class="preparation-time">
                  <dt class="sr-only">{this.$t('recipe_card.cooking_time')}</dt>
                  <dd>
                    <time>{this.preparationTimeWithSuffix}</time>
                  </dd>
                </dl>
              )}
              {this.recipeLabels.length ? (
                <div>
                  {this.recipeLabels.map((r) =>
                    r.backgroundColor && r.textColor && r.title ? (
                      <div
                        class="mb-0 w-full py-1.5 text-center text-xs font-bold uppercase"
                        style={{
                          backgroundColor: r.backgroundColor,
                          color: r.textColor,
                        }}
                      >
                        {formatTitle(r.title)}
                      </div>
                    ) : null
                  )}
                </div>
              ) : null}
              {this.isOutOfStock ? (
                <div>
                  <div class="bg-rb-dark-gray mb-0 flex items-center justify-center py-1.5 text-xs font-bold uppercase text-white">
                    {this.$t('product_card.out_of_stock')}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {!this.hideActions && !this.paused && !this.isOutOfStock ? (
            <div class="product-card-footer">
              {this.selected ? (
                <label class="button button-label button-selected">
                  <span class="label-text">
                    {this.$t('product_card.selected')}
                  </span>
                  <Button
                    class="button-remove button-transparent"
                    disabled={!this.editable}
                    onClick={this.handleUnselect}
                    ref="removeButton"
                    title={this.$t('product_card.remove')}
                  >
                    <IconTrash aria-hidden="true" class="icon" />
                  </Button>
                </label>
              ) : this.editable ? (
                <Button
                  class="button button-label button-add"
                  disabled={!this.editable}
                  onClick={this.handleSelect}
                  ref="addButton"
                >
                  <span class="label-text">{this.$t('product_card.add')}</span>
                  <IconPlus aria-hidden="true" class="icon ml-2.5" />
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    );
  },
});
