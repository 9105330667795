import { NuxtLink } from '#components';
import IconChevronRight from '@/assets/icons/icon-chevron-right.svg';

export default defineComponent({
  name: 'MoreWeeksButton',
  render(): VNode {
    return (
      <NuxtLink
        to={{ name: 'delivery-calendar' }}
        class="border-1 border-rb-green h-20 w-full max-w-14 rounded-r-sm !no-underline !opacity-100"
      >
        <label class="text-rb-dark-green flex w-full flex-col items-center justify-center text-center">
          <IconChevronRight>&gt;</IconChevronRight>
          <span>{this.$t('global.more')}</span>
        </label>
      </NuxtLink>
    );
  },
});
